import React from 'react';
import { graphql } from 'gatsby';

import './layout.css';


export const query = graphql`
  query($slug: String!) {
    projectsJson(slug: { eq: $slug }) {
      title
      description
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sections {
        heading
        narrative
        image {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Layout = ({ children }) => (
  <React.Fragment>
    {/* <header className="header">
      <Link to="/">Gregory King</Link>
    </header> */}
    {/* <Image fluid={item.image.childImageSharp.fluid} alt={item.image.name} /> */}
    <main className="content">{children}</main>
  </React.Fragment>
);

export default Layout;
